// Styles SCSS
import "../sass/app.scss";

// SVG polyfiil
import "svgxuse";

// Flash Info
import "jquery.marquee";

// pagination
import "./utils/pagination.js";

//Tabs
import { Tabs } from "./utils/tabs";

import Swiper from "swiper/bundle";

// Lightbox
import "./utils/fancybox";

// LazyLoad
import lazyLoadInit from "./lazyload-init";

lazyLoadInit();
function eventFire(el, etype) {
  if (el.fireEvent) {
    el.fireEvent("on" + etype);
  } else {
    var evObj = document.createEvent("Events");
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}
jQuery(document).ready(() => {
  jQuery("#access-link").on("click", function () {
    eventFire(document.getElementById("uci_link"), "click");
  });
  // Toggle unclic.twig + change le chevron
  jQuery("#demarches").click(function () {
    jQuery("#unclic").toggle();
    jQuery("#unclic").toggleClass("active");
    jQuery(this).toggleClass("rotate");
  });

  var demarches_slider = new Swiper(".unclic", {
    spaceBetween: 20,
    slidesPerView: 6,
    // Navigation arrows
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },
    // responsive
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      1440: {
        slidesPerView: 6,
        spaceBetween: 10,
      },
    },
  });

  // .js-demarches on click slidetoggle #unclic
  jQuery(".js-demarches").on("click", function () {
    jQuery("#unclic").slideToggle();
    jQuery(this).toggleClass("hide");
    demarches_slider.update();
  });

  //      if (document.querySelector('.galerie') !== null) {
  //     const grid = document.querySelector('.galerie');
  //     new Masonry(grid, {
  //         itemSelector: '.galerie__item',
  //         columnWidth: 280,
  //         gutter: 10
  //     });
  // }

  // $grid = jQuery('.galerie');

  //    $grid.imagesLoaded().progress( function() {
  //    $grid.masonry({
  //   itemSelector: '.galerie__item',

  //   columnWidth: '280',
  //   gutter: 10
  // });
  //  });
  jQuery(".gform_confirmation_wrapper").appendTo("body");
  jQuery(".gform_validation_errors").appendTo("body");

  jQuery(".ticker").marquee({
    speed: 90,
    gap: 30,
    delayBeforeStart: 0,
    direction: "left",
    duplicated: false,
    pauseOnHover: true,
  });

  if (document.querySelector(".accordion-container") !== null) {
    jQuery(".accordion-container").each(function () {
      // start with all panels closed

      var accordion = jQuery(this);
      var items = accordion.find(".ac");
      items.each(function () {
        items.find(".content__accordion").hide();
        var item = jQuery(this);
        var title = item.find(".ac-q");
        var content = item.find(".content__accordion");
        title.on("click", function () {
          items.removeClass("active");
          item.toggleClass("active");
          title.find(".fa-circle-plus").toggleClass("hidden");
          title.find(".fa-circle-minus").toggleClass("hidden");
          content.slideToggle();
          items.not(item).find(".content__accordion").slideUp();
        });
      });
    });
  }
  if (document.querySelector(".tabs-container") !== null) {
    new Tabs({
      elem: "tabs",
      open: 0,
    });
  }
  jQuery("#submit-filter-annuaire").on("click", function (e) {
    e.preventDefault();
    console.log("click");

    var filter = jQuery("#filter-annuaires");
    var loading = jQuery(".load-bar");
    jQuery.ajax({
      url: filter.attr("action"),
      data: filter.serialize(),
      type: "POST",
      action: "ajax_function_annuaire",
      beforeSend: function () {
        jQuery(".annuaires").html("");
        loading.toggleClass("invisible");
      },
      success: function (data) {
        loading.toggleClass("invisible");
        jQuery(".annuaires").html(data);
      },
    });
    return false;
  });

  jQuery('[data-fancybox="galerie"]').fancybox({
    thumbs: {
      autoStart: true,
    },
  });

  jQuery(".reservation_calendar").insertBefore(".gform_wrapper");
  jQuery(function () {
    var $acces = jQuery("#sticky-access"),
      $connect = jQuery("#sticky-connect");

    $acces.find(".trigger").on("click", function (event) {
      event.preventDefault();
      $acces.toggleClass("active");
      $connect.removeClass("active");
    });

    $connect.find(".trigger").on("click", function (event) {
      event.preventDefault();
      $connect.toggleClass("active");
      $acces.toggle("slide");
      $acces.removeClass("active");
    });
  });

  jQuery(".js-search, .js-search-close").on("click", function () {
    jQuery(".js-search-overlay").toggleClass("active");
    // fidn input focus but no .focus() method because it's deprecated
    jQuery(".js-search-overlay input").focus();
  });
  // if it escape key remove class active
  jQuery(document).keyup(function (e) {
    if (e.key === "Escape") {
      // if .js-search-overlay has class active remove it
      if (jQuery(".js-search-overlay").hasClass("active")) {
        jQuery(".js-search-overlay").removeClass("active");
      }
    }
  });
  jQuery(".container-events").pagify(10, ".events");

  jQuery("#js-menu-open, #js-menu-close, #js-menu-overlay").on(
    "click",
    function () {
      jQuery("#js-menu-overlay").toggleClass("active");
      jQuery("#js-menu").toggleClass("active");
      jQuery("#js-menu-close").toggleClass("hidden");
      jQuery("#js-menu-open").toggleClass("hidden");
    }
  );

  // on click js-responsive-open-menu open ul sibling
  jQuery(".responsive-menu__link").on("click", function () {
    jQuery(this).siblings("ul").slideToggle();
    //parent add class active
    jQuery(this).parent().toggleClass("active");
  });
  jQuery(".open-ul").on("click", function () {
    jQuery(this).parent().siblings("ul").slideToggle();
    //parent add class active
    jQuery(this).parent().parent().toggleClass("active");
  });

  // When the user scrolls the page, execute myFunction
  window.onscroll = function () {
    myFunction();
  };

  // Get the navbar
  var navbar = document.getElementById("navbar");

  // Get the offset position of the navbar
  var sticky = navbar.offsetTop;

  // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
  function myFunction() {
    if (window.scrollY >= sticky && window.innerWidth > 1024) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }
  }

  // jQuery("#field_5_21 select").on("change", function () {
  //   var self = jQuery(this);

  //   if (self.val() !== "2") {
  //     jQuery("#field_5_20").show();
  //     console.log("show");
  //   } else {
  //     jQuery("#field_5_20").hide();
  //     console.log("hide");
  //   }
  // });
});
